import { useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Axios from "../../axios";
import OnboardinError from "../../components/OnboardingError/OnboardingError";
import OnboardingSideContent from "../../components/OnboardingSideContent/OnboardingSideContent";
import ResetLink from "../../components/ResetLink/ResetLink";
import TextInput from "../../components/TextInput/TextInput";

const ForgotPasswordPage = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { register, handleSubmit } = useForm({});
  const { env } = useSelector((state) => state);
  const navigate = useNavigate();

  const onSubmit = (values) => {
    setLoading(true);
    Axios()
      .post(`${process.env.REACT_APP_API_URL}/v1/password_reset_token`, values)
      .then((res) => {
        navigate(`/reset-link`);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          setError("forgot_password.error");
          setTimeout(() => setError(null), 2000);
        }
        setLoading(false);
      });
  };

  return (
    <div className="admin-login">
      <OnboardingSideContent />
      <div className="main-content">
        <div class="login-page-content">
          <form className="admin-form">
            <div className="logo-small-show">
              <a href={env.CUSTOMER_URL}>
                <img
                  src="https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/logo.svg"
                  alt=""
                />
              </a>
            </div>
            {props.sent ? (
              <ResetLink />
            ) : (
              <Fragment>
                <h3 className="mb-15">{t("forgot_password.header")}</h3>
                <p classname="pwinstruction">
                  {t("forgot_password.sub_header")}
                </p>

                {error && <OnboardinError error={error} />}

                <TextInput
                  label="forgot_password.email"
                  type="email"
                  name="email"
                  register={register}
                />

                <button
                  className="green-btn block-btn mt-10"
                  type="submit"
                  onClick={handleSubmit((values) => onSubmit(values))}
                >
                  {loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    t("forgot_password.button")
                  )}
                </button>
              </Fragment>
            )}
            <p className="text-center new-account mt-5">
              <Link to="/login">{t("forgot_password.cancel")}</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
