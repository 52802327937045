import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const metricsSlice = createSlice({
    name: 'plans',
    initialState,
    reducers : {
        update_metrics : (state, action) => {
            return action.payload
        }        
    }
})

export const {update_metrics} = metricsSlice.actions;

export default metricsSlice.reducer;