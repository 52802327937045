import { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HostingList from "../../../components/HostingList/HostingList";
import HostingOrderModal from "../../../components/HostingOrderModal/HostingOrderModal";
import NoDomains from "../../DomainsPage/NoDomains";
import SuccessMessage from "../../../components/SuccessMessage/SuccessMessage";
import * as $ from "jquery";
import Axios from "../../../axios";
import { logout, getCookie } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  clear_page_data,
  update_page_data,
} from "../../../Redux/reducers/pageSlice";
import HostingRenewalModal from "../../../components/HostingRenewalModal/HostingRenewalModal";
import HostingUpgradeModal from "../../../components/HostingUpgradeModal/HostingUpgradeModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import ListLoadingIcon from "../../../components/ListLoadingIcon/ListLoadingIcon";
import PageControl from "../../../components/PageControl/PageControl";

const CustomerHosting = (props) => {
  const [hosting, setHosting] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const { env, user, page, plans } = useSelector((state) => state);
  const headers = { "access-token": getCookie("access-token") };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const update = (data) => dispatch(update_page_data(data));
  const {
    error,
    loading,
    selected_subscription,
    search_query,
    success_message,
    list,
    list_page,
    list_loading,
  } = page;

  const getHostingList = () => {
    update({ list_loading: true });
    const { id } = params;
    const d = {
      ...(!search_query ? {} : { domain_name: search_query }),
      customer_id: parseInt(id),
      pagination: {
        page: list?.meta_data?.query === search_query ? list_page : 1,
        page_size: 30,
      },
    };

    Axios()
      .post(`${env.API_URL}/v1/enterprise/subscriptions`, d, {
        headers: headers,
      })
      .then((res) => {
        update({ list: res.data });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
          navigate("/login");
        }
      })
      .finally(() => update({ list_loading: false }));
  };

  const orderSuccess = () => {
    setShowModal(false);
    $("#exampleModal").hide();
    $(".modal-backdrop").remove();
    setMessage({ type: "success", content: "Hosting order successful" });
    update({ success_message: "Hosting order successful" });
    getHostingList();
    setTimeout(() => update({ success_message: null }), 2000);
  };

  useEffect(() => {
    if (!user.data) return;
    getHostingList();
    return () => dispatch(clear_page_data());
  }, [user]);

  useEffect(() => {
    if (!user.data) return;
    if (list_page !== list?.meta_data?.page) getHostingList();
  }, [user, list_page]);

  return (
    <Fragment>
      <div className="tab-body">
        <div className="row">
          <div className="col-sm-12">
            <SearchBar
              query={search_query}
              click={() => getHostingList()}
              change={(e) => update({ search_query: e.target.value })}
              loading={loading}
              classname="col-sm-9 fl-left"
            />
            <button
              className="fl-right btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
              id="myBtn"
            >
              Add Hosting
            </button>
          </div>
        </div>
        {success_message && <SuccessMessage message={success_message} />}
        {list_loading && <ListLoadingIcon />}
        {!list_loading &&
          (list && list?.meta_data?.total_count > 0 ? (
            <HostingList data={hosting} />
          ) : (
            <NoDomains />
          ))}
        {list && list?.meta_data?.total_pages > 1 && <PageControl />}
      </div>
      <HostingOrderModal
        show={showModal}
        close={() => setShowModal(false)}
        successMessage={orderSuccess}
      />
      {selected_subscription && plans && (
        <HostingRenewalModal
          data={selected_subscription}
          getHostingList={() => getHostingList()}
        />
      )}
      {selected_subscription && plans && (
        <HostingUpgradeModal
          data={selected_subscription}
          getHostingList={() => getHostingList()}
        />
      )}
    </Fragment>
  );
};

export default CustomerHosting;
