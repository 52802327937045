import { useTransition } from "react";
import Axios from "../../axios";
import Store from "../../Redux/Store";
import { logout, getCookie, clear_cart_id } from "../../utils/utils";

const env = Store.getState().env;

const verifyPayment = (
  props,
  setVerificationError,
  setVerifying,
  navigate,
  getBalance
) => {
  setVerifying("topup");
  const { action, status, transaction_id, transaction_ref } =
    props.location.state;
  if (action === "payment" && status === "cancelled") {
    setVerificationError("dashboard.errors.verification_canceled");
    setVerifying(null);
    setTimeout(() => {
      setVerificationError(null);
      window.history.replaceState({}, document.title);
    }, 5000);
  }
  if (action === "payment" && status === "completed") {
    const data = {
      transaction_id: transaction_id,
      transaction_ref: transaction_ref,
    };
    Axios()
      .post(`${env.API_URL}/v1/payment/verify`, data, {
        headers: { "access-token": getCookie("access-token") },
      })
      .then((res) => {
        setTimeout(() => {
          setVerifying(null);
          getBalance();
        }, 5000);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
          navigate("/login");
        }
      })
      .finally(() => window.history.replaceState({}, document.title));
  }
};

const processOrder = (
  props,
  setVerifying,
  navigate,
  getMetrics,
  setError,
  t
) => {
  setVerifying("order");
  clear_cart_id();
  const { transaction_id, transaction_ref, order_id, cart_id } =
    props.location.state;
  const data = {
    transaction_id: transaction_id,
    transaction_ref: transaction_ref,
  };
  Axios()
    .post(`${env.API_URL}/v1/rest/${order_id}/${cart_id}/process`, data, {
      headers: { "access-token": getCookie("access-token") },
    })
    .then((res) => {
      const error = res?.data.find((e) => e.status !== "successful");
      if (error) {
        setError(error?.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
      setTimeout(() => {
        getMetrics();
        setVerifying(null);
        localStorage.removeItem("cart_id");
        window.history.replaceState({}, document.title);
      }, 5000);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        logout();
        navigate("/login");
      } else {
        setError(err?.response?.data?.message || t("try_again"));
        setTimeout(() => setError(null), 5000);
      }
    })
    .finally(() => {
      setVerifying(null);
      window.history.replaceState({}, document.title);
    });
};

export { verifyPayment, processOrder };
