import { Suspense, useEffect, Fragment } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import Signup from "./containers/Signup/Signup";
import Login from "./containers/Login/Login";
import ForgotPasswordPage from "./containers/ForgotPasswordPage/ForgotPasswordPage";
import NewPasswordPage from "./containers/NewPasswordPage/NewPasswordPage";
import AdminMain from "./containers/AdminMain/AdminMain";
import { get_cart_id } from "./utils/utils";
import { Provider, useDispatch, useSelector } from "react-redux";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import OrderProcessing from "./containers/OrderProcessing/OrderProcessing";
import { Navigate, Routes } from "react-router";
import { setPlans, resetSupportData } from "./utils/utils";
import Store, { persistor } from "./Redux/Store";
import SiteLoadingComponent from "./components/SiteLoadingComponent/SiteLoadingComponent";
import { PersistGate } from "redux-persist/integration/react";
import { update_page_data } from "./Redux/reducers/pageSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    resetSupportData();
    get_cart_id();
    setPlans();
    dispatch(update_page_data());
  }, []);

  return (
    <Fragment>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<SiteLoadingComponent />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-link" element={<ForgotPasswordPage sent />} />
              <Route path="/enterprise/*" element={<AdminMain />} />
              <Route path="/reset/complete/:id" element={<NewPasswordPage />} />
              <Route path="/orders" element={<OrderProcessing />} />
              <Route path="" element={<Navigate to="/enterprise" />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </PersistGate>
      </Provider>
    </Fragment>
  );
}

export default App;
