import { NavLink, Navigate, Link } from "react-router-dom";
import CustomerEdit from "./CustomerEdit/CustomerEdit";
import { Route, Routes } from "react-router";
import CustomerDomains from "./CustomerDomains/CustomerDomains";
import CustomerHosting from "./CustomerHosting/CustomerHosting";

const CustomerProfile = (props) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <p className="back-arrow">
          <Link to="/enterprise/customers">
            <i className="fas fa-chevron-left"></i>Customers
          </Link>
        </p>
      </div>
      <div className="col-sm-12">
        <div className="tab-overflow">
          <div className="sub-tab-nav">
            <ul>
              <li>
                <NavLink to={"profile"} activeClassName="active">
                  Personal Information
                </NavLink>
              </li>
              <li>
                <NavLink to={"domains"} activeClassName="active">
                  Domains
                </NavLink>
              </li>
              <li>
                <NavLink to={"hosting"} activeClassName="active">
                  Hosting
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Routes>
          <Route path={"profile"} element={<CustomerEdit />} />
          <Route path={"domains"} element={<CustomerDomains />} />
          <Route path={"hosting"} element={<CustomerHosting />} />
          <Route path="" element={<Navigate to="profile" />} />
        </Routes>
      </div>
    </div>
  );
};

export default CustomerProfile;
