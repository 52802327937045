import { createSlice } from "@reduxjs/toolkit";
import devEnvironment from "../../env-variables/development";
import prodEnvironment from "../../env-variables/production";
import stagingEnv from "../../env-variables/staging";

const initialState = null;

export const paymentSlice = createSlice({
  name: "env",
  initialState,
  reducers: {
    update_payment_status: (state, action) => {
      return { ...state, ...action.payload };
    },
    clear_payment_message: () => initialState,
  },
});

export const { update_payment_status, clear_payment_message } =
  paymentSlice.actions;

export default paymentSlice.reducer;
