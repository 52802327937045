import { Fragment } from 'react'
import { useEffect, useState } from 'react'
import * as $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { logout } from '../../utils/utils'

const AdminHeader = () => {

	const {t} = useTranslation()

	const [dropdown , setDropdown] = useState(false)
	const toggle = (e) => {
		e.preventDefault()
		if (dropdown === false) {
			$(".user-prof").slideDown(300)
			setDropdown(true)
		} else {
			$(".user-prof").slideUp(300)
			setDropdown(false)
		}
	}

	const handle_logout = () => {
		logout()
	}
	useEffect(() => {
		$('.fa-bars').on("click", function(){
			$('.page-content-overlay').toggleClass('open');
			$('.page-sidebar-wrapper').toggleClass('open');
		});
	} , [])

	const {env, user} = useSelector(state => state)

    return (
		<Fragment>
			<div className='admin-header clearfix'>
				<div style={{float:'left'}}>
					<i className="fas fa-bars menu-trigger"></i>
					<a href={env.CUSTOMER_URL}>
						<img src='https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/logo-sidebar-white.svg' alt=''/>
					</a>
				</div>
				<div style={{float: 'right'}}>
					<div className='name-initials' onClick={(e) => toggle(e)}>
						{user.data && <span>
							<i className='fas fa-user'></i>{`${user.data.first_name} ${user.data.last_name}`}
						</span>}
					</div>
					<div className='user-prof shadow'>
						<a href='/enterprise/settings'>{t('admin_header.profile')}</a>
						<a onClick={() => handle_logout()}>{t('admin_header.logout')}</a>
					</div>
				</div>
			</div>
		</Fragment>

    )
}

export default AdminHeader
