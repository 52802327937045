import { useTranslation } from "react-i18next";

const NoDomains = (props) => {
  const { t } = useTranslation();
  return (
    <div className="ls-table mt-2">
      <p className="table-empty">
        <i className={props.icon || "fas fa-globe"}></i>
        <span>{t("domains_page.empty")}</span>
      </p>
    </div>
  );
};

export default NoDomains;
