import { useTranslation } from 'react-i18next'

const CountryInput = (props) => {

    const {t} = useTranslation()
    const {changeCountry, errors, states, register} = props
    

    return (
        <div className="row">
            <div className="col-sm-6">
            <div className="input-ctn">
                <label>{t('add_customer.form.country')}</label>
                <select className="form-control" data-testid = "contact.country" {...register('contact.country')} onChange={changeCountry}>
                <option value=""></option>
                <option value="NG">Nigeria</option>
                </select>
            {errors?.contact && <span className="invalid-feedback">{t(errors.contact.country?.message)}</span>}
            </div>
            </div>

            <div className="col-sm-6">
            <div className="input-ctn">
                <label>{t('add_customer.form.city')}</label>
                {
                    states &&
                    <select className="form-control" data-testid = "contact.city" {...register('contact.city')} >
                        <option></option>
                        {states.map((city , index) => <option key={index} value={city.name}>{city.name}</option>)}
                    </select>
                }
                {errors?.contact && <span className="invalid-feedback">{t(errors.contact?.city?.message)}</span>}
            </div>
            </div>

        </div>
    )
}

export default CountryInput
