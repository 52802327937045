import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ButtonWithSpinner = (props) => {

  
  const {t} = useTranslation()
  const {loading , onclick, classname, text, type, id} = props
  return loading ?
    <button disabled className={classname} id={id}><i className = 'fas fa-circle-notch fa-spin'/></button> :
    <button className={classname} id={id} type={type || 'button'} onClick={(e) => onclick(e)}>{t(text)}</button>
}

export default ButtonWithSpinner