import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const OnboardinError = (props) => {
    const {t} = useTranslation()
    const {error} = useSelector(state => state.page)
    return (
        <div className="error-messaging ">
            <span data-testid="onboarding.error">{t(props.error) || error}</span>
        </div>
    )
}

export default OnboardinError
