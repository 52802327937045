import Axios from "../../../axios";
import { update_userdata } from "../../../utils/utils";
import * as Yup from "yup";
import store from "../../../Redux/Store";

/**
 * Schema for signup form
 */
export const schema = Yup.object({
  first_name: Yup.string().required("empty_first_name"),
  last_name: Yup.string().required("empty_last_name"),
  organisation_name: Yup.string().required("empty_company_name"),
  email: Yup.string().email().required("empty_email"),
  password: Yup.string()
    .required("password.required")
    .matches(/^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, "password.invalid"),
});

export const submit = (values, setLoading, setError, navigate) => {
  const { user } = store.getState();
  setLoading(true);
  Axios()
    .post(process.env.REACT_APP_API_URL + "/v1/enterprise/signup", values, {
      headers: { "Access-Control-Allow-Origin": "http://localhost" },
    })
    .then((response) => {
      setError(null);

      const expiry_date = new Date();
      expiry_date.setDate(expiry_date.getDate() + 1);
      document.cookie = `access-token=${
        response.data["access-token"]
      };expires=${expiry_date.toUTCString()};path=/;`;
      setTimeout(() => {
        update_userdata(navigate);
        navigate(`/enterprise/${user.data.is_admin ? "dashboard" : "domains"}`);
      }, 0);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      const error = err.response;
      if (error) {
        if (error.data.message.match(new RegExp("empty value for"))) {
          setError(`empty_${error.data.message.split(" ").pop()}`);
        } else if (
          error.data.message.match(
            new RegExp("A user with this email already exists")
          )
        ) {
          setError(`used_email`);
        }
        setTimeout(() => setError(null), 2000);
      } else {
        setError("error_network");
        setTimeout(() => setError(null), 2000);
      }
    });
};
