import { useTranslation } from "react-i18next";

const SearchBar = (props) => {
  const { t } = useTranslation();

  const { query, click, change, loading } = props;

  const check = (e) => {
    e.code === "Enter" && click();
  };

  return (
    <div className={`${props.classname || "col-sm-12 "}`}>
      <div className="row mb-30">
        <div className="col-lg-3 col-md-5 col-sm-6">
          <input
            name="text"
            value={query}
            onChange={change}
            onKeyUp={(e) => check(e)}
            placeholder={t("searchbar.search")}
            type="text"
          />
        </div>
        <div className="col-lg-2 col-md-4 col-sm-3">
          {loading ? (
            <button
              onClick={click}
              className="btn block-btn btn-success mb-2 cus-src-btn"
            >
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          ) : (
            <button
              onClick={click}
              className="btn block-btn btn-success mb-2 cus-src-btn"
            >
              {t("searchbar.search")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
