import * as $ from "jquery";
import Axios from "../axios";
import Store from "../Redux/Store";
import { login, logOut } from "../Redux/reducers/userSlice";
import axios from "axios";
import { set_plans } from "../Redux/reducers/plansSlice";
import random from "randomstring";
import { supportLogin, supportLogout } from "../Redux/reducers/supportSlice";

export const toCancel = (e, selector) => {
  const el = $(selector);

  const { top, bottom, left, right } = el[0].getBoundingClientRect();
  const { clientX, clientY } = e;
  const conditions = [
    clientX < left,
    clientX > right,
    clientY < top,
    clientY > bottom,
  ];

  return conditions.includes(true) && clientX !== 0;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const req_type = () => {
  const { support } = Store.getState();
  if (support?.type === "user") return "rest";
  return Store.getState((state) => state).user.req_type;
};

export const update_userdata = async (navigate, location, site_callback) => {
  const data = { "access-token": getCookie("access-token") };
  let user;
  Axios()
    .get(process.env.REACT_APP_API_URL + "/v1/me", {
      headers: data,
      withCredentials: true,
    })
    .then((res) => {
      Store.dispatch(login(res.data));
      user = res.data;
      return user;
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        if (new RegExp(/^\/Login/gim).test(location?.pathname)) {
          return;
        }
        logout();
        navigate("/login");
      }
    });
};

export const getMetrics = (query) => {
  const env = Store.getState().env;
  const req_type = Store.getState().user.req_type;
  const headers = { "access-token": getCookie("access-token") };

  const p = Axios()
    .get(`${env.API_URL}/v1/${req_type}/dashboard/metrics`, {
      headers: headers,
    })
    .then((res) => {
      return res.data[query] === 0;
    });

  return p;
};

export const get_all_errors = (errors) => {
  const values = Object.keys(errors);
  const ar = [];
  for (let i = 0; i < values.length; i++) {
    const n = values[i];
    ar.push(errors[n].message);
  }
  return ar;
};

export const logout = () => {
  Store.dispatch(logOut());
  Store.dispatch(supportLogout());
  localStorage.removeItem("auth-id");
  setCookie("support", null, -20);
  setCookie("auth-id", null, -20);
  setCookie("access-token", null, -20);
  setTimeout(() => {
    window.location.href = "/login";
  }, 0);
};

export const addHandler = (url) => `http://${url}`;

export const redirect = (target) => {
  window.location.href = target;
};

export const clipQuery = (name) => {
  const n = name.split(".");
  if ((n[0] === "ww" || n[0] === "www") && n.length > 2) {
    n.shift();
  }
  return n.join(".");
};

export const redirectToLogin = () => {
  window.location.href = `${process.env.REACT_APP_ADMIN_URL}/login?from=${window.location.href}`;
};

export const setPlans = () => {
  const env = Store.getState().env;
  Axios()
    .get(`${env.API_URL}/v1/rest/plans`)
    .then((res) => {
      Store.dispatch(set_plans(res.data));
    });
};

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; Domain=.leanstack.co; path=/";
};

export const get_cart_id = () => {
  const expiry_date = new Date();
  expiry_date.setDate(expiry_date.getDate() + 1);

  const id = !getCookie("cart_id")
    ? setCookie(
        "cart_id",
        random.generate({
          length: 16,
          charset: "QWERTYUIOPASDFGHJKLZXCVBNM1234567890",
        })
      )
    : getCookie("cart_id");

  return id;
};

export const clear_cart_id = () => {
  setCookie("cart_id", null, -20);
};

export const add_to_cart = (data) => {
  const env = Store.getState().env;
  const url = `${env.API_URL}/v1/rest/cart/add`;
  return axios.post(url, data);
};

export const update_cart = (data) => {
  const env = Store.getState().env;
  const url = `${env.API_URL}/v1/rest/cart/add`;
  data.cart_id = get_cart_id();
  return axios.post(url, data);
};

export const resetSupportData = () => {
  const { support } = Store.getState();
  if (!support.type && getCookie("support")) {
    Store.dispatch(supportLogin(JSON.parse(getCookie("support"))));
  }
};
