import React from "react";

const ListLoadingIcon = () => {
  return (
    <div className="col-sm-12">
      <div className="ls-table" style={{ height: "160px" }}>
        <div class="domain-search-results shadow">
          <div class="spinner-ctn">
            <div class="loading-gif">
              <div class="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span>Loading</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListLoadingIcon;
