import HostingItem from "./HostingItem";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const HostingList = (props) => {
    const { t } = useTranslation();
    const { user, page, plans } = useSelector((state) => state);

    const { list, selected_subscription } = page;
    const [highestRank, setHighRank] = useState(4);

    return (
        <div className="ls-table mt-3">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {user?.data?.is_super_admin && <th>{t("hosting_table.customer")}</th>}
                            <th>{t("hosting_table.domain")}</th>
                            <th>{t("hosting_table.plan")}</th>
                            <th>{t("hosting_table.start")}</th>
                            <th>{t("hosting_table.exp")}</th>
                            <th>{t("hosting_table.status")}</th>
                            <th className="text-center">{t("hosting_table.action")}</th>
                        </tr>
                    </thead>
                    <tbody>{list?.data && list.data.map((d) => <HostingItem key={d.id} data={d} highestRank={highestRank} />)}</tbody>
                </table>
            </div>
        </div>
    );
};

export default HostingList;
