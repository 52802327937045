import { useTranslation } from "react-i18next";

const DomainStatus = (props) => {
    const { status } = props;

    const badge_type = () => {
        switch (true) {
            case /^PAID$|^PROCESSED$|^ACTIVE$/gi.test(status):
                return "badge-success";
            case /^pending$|^processing$|^PENDING_PROCESSING$|^TRANSFER PENDING$|^Pending Deletion$|^Pending Processing$/gi.test(status):
                return "badge-warning";
            case /^deleted$|^Expired$|^disabled$|^overdue$/gi.test(status):
                return "badge-danger";
            case /^suspended$/gi.test(status):
                return "badge-info";
            default:
                return "badge-success";
        }
    };
    const { t } = useTranslation();
    return <span className={`badge ${badge_type()}`}>{t(status)}</span>;
};

export default DomainStatus;
