import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list : null,
    loading : null,
    selected_subscription : null,
    search_query : "",
    error : null,
    success_message : null,
    list_page : 1,
    site_loading : true
}

export const pageSlice = createSlice({
    name: 'page',
    initialState : {...initialState},
    reducers : {        
        update_page_data : (state, action) => {
                return {
                    ...state,
                    ...action.payload
                };
        },
        clear_page_data : (state) => {
            return {...initialState, site_loading : false}
        }
    }
})

export const {update_page_data, clear_page_data} = pageSlice.actions;

export default pageSlice.reducer;