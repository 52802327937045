import { Fragment, Suspense, lazy, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import * as $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import SiteLoadingComponent from "../../components/SiteLoadingComponent/SiteLoadingComponent";
import { update_page_data } from "../../Redux/reducers/pageSlice";
import Axios from "../../axios";
import { getCookie, logout } from "../../utils/utils";
import { login } from "../../Redux/reducers/userSlice";
import CustomerProfile from "../CustomerProfile/CustomerProfile";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import DomainStatus from "../DomainsPage/DomainItem/DomainStatus";

const AdminHeader = lazy(() =>
  import("../../components/AdminHeader/AdminHeader")
);
const AdminSidebar = lazy(() =>
  import("../../components/AdminSidebar/AdminSidebar")
);
const CustomersPage = lazy(() => import("../CustomersPage/CustomersPage"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const SettingsPage = lazy(() => import("../SettingsPage/SettingsPage"));
const WalletHistoryPage = lazy(() =>
  import("../WalletHIstoryPage/WalletHistoryPage")
);
const CustormerProfile = lazy(() =>
  import("../CustomerProfile/CustomerProfile")
);
const DomainsPage = lazy(() => import("../DomainsPage/DomainsPage"));
const DomainManagement = lazy(() =>
  import("../DomainManagement/DomainManagement")
);
const HostingPage = lazy(() => import("../HostingPage/HostingPage"));
const ManageHosting = lazy(() => import("../ManageHosting/ManageHosting"));
const OrdersPage = lazy(() => import("../../components/OrdersPage/OrdersPage"));
const OrganizationsPage = lazy(() =>
  import("../../components/OrganizationsPage/OrganizationsPage")
);
const SuperAdminDomain = lazy(() =>
  import("../../components/SuperAdminDomain/SuperAdminDomain")
);
const SupportAlert = lazy(() =>
  import("../../components/SupportAlert/SupportAlert")
);
const UsersPage = lazy(() => import("../UsersPage/UsersPage"));

const AdminMain = (props) => {
  const { user, support } = useSelector((state) => state);
  const dispatch = useDispatch();
  const update = (d) => dispatch(update_page_data(d));
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    $(".page-content-overlay").removeClass("open");
    $(".page-sidebar-wrapper").removeClass("open");
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    const data = { "access-token": getCookie("access-token") };
    Axios()
      .get(process.env.REACT_APP_API_URL + "/v1/me", {
        headers: data,
        withCredentials: true,
      })
      .then((res) => {
        dispatch(login(res.data));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          if (new RegExp(/enterprise/gim).test(location?.pathname)) {
            logout();
            setLoading(false);
            navigate("/login");
          }
          return;
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Fragment>
      {loading && <SiteLoadingComponent />}
      {!loading && <AdminHeader />}
      <div className="admin-body">
        {!loading && <AdminSidebar close={() => handleClose()} />}
        <div className="page-content-wrapper">
          <div className="admin-main-bg">
            {support.name && <SupportAlert />}
            <Suspense fallback={<SiteLoadingComponent />}>
              <Routes>
                {user?.data?.is_admin && support?.type !== "user" && (
                  <Route path="dashboard" element={<Dashboard />} />
                )}
                {user?.data?.is_admin && (
                  <Route path="customers" element={<CustomersPage />} />
                )}
                <Route path="customers/:id/*" element={<CustomerProfile />} />
                {
                  <Route
                    path="domains/*"
                    element={
                      user?.data?.is_super_admin && support.type === null ? (
                        <SuperAdminDomain />
                      ) : (
                        <DomainsPage />
                      )
                    }
                  />
                }

                <Route path="domain/:id" element={<DomainManagement />} />
                <Route path="hosting" element={<HostingPage />} />
                <Route path="hosting/:id" element={<ManageHosting />} />
                {user?.data?.is_admin && (
                  <Route
                    path="wallet-history"
                    element={<WalletHistoryPage />}
                  />
                )}
                {/* {user?.data?.is_admin && (
                  <Route path="users" element={<UsersPage />} />
                )} */}
                <Route path="orders" element={<OrdersPage />} />
                <Route path="organisations" element={<OrganizationsPage />} />
                <Route path="settings/*" element={<SettingsPage />} />
                <Route
                  path=""
                  element={
                    <Navigate
                      to={user?.data?.is_admin ? "dashboard" : "domains"}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminMain;
