import { Fragment } from "react"
import { useSelector } from "react-redux"

const ResetLink = () => {

    const {env} = useSelector(state => state)

    return (
        <Fragment>
            <center><img href={env.CUSTOMER_URL} alt ="" src="https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/mail-sent.svg" className="text-cente mb-3" /></center>
            <h4 className="mb-15 text-center">Email has been sent</h4>
            <p className="pwinstruction text-center fs-16 cl-grey fw-light">A password reset link has been sent to your email address. Kindly check your inbox</p>
        </Fragment>
    )
}

export default ResetLink
