import { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DomainModal from "../../../components/DomainModal/DomainModal";
import DomainList from "../../DomainsPage/DomainList.js/DomainList";
import SuccessMessage from "../../../components/SuccessMessage/SuccessMessage";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { clipQuery, logout, getCookie } from "../../../utils/utils";
import * as $ from "jquery";
import Axios from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import {
  clear_page_data,
  update_page_data,
} from "../../../Redux/reducers/pageSlice";
import SearchBar from "../../../components/SearchBar/SearchBar";
import ListLoadingIcon from "../../../components/ListLoadingIcon/ListLoadingIcon";
import PageControl from "../../../components/PageControl/PageControl";

const CustomerDomains = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [orderSuccess, setorderSuccess] = useState(null);
  const [searching, setSearching] = useState(false);
  const params = useParams();
  const { id } = params;

  const headers = { "access-token": getCookie("access-token") };
  const {
    env,
    user,
    page: {
      list,
      success_message,
      error,
      loading,
      list_loading,
      list_page,
      search_query,
    },
  } = useSelector((state) => state);

  const [userDomains, setUserDomains] = useState(null);

  const [availableDomain, setAvailableDomain] = useState(false);
  const [domainMessage, setDomainMessage] = useState(null);
  const [domainData, setDomainData] = useState(null);

  const dispatch = useDispatch();
  const update = (data) => dispatch(update_page_data(data));

  const catch_all = (err) => {
    if (err.response) {
      if (err.response.status === 422) {
        setDomainMessage({ type: "err", content: err.response.data.message });
        setTimeout(() => setDomainMessage(null), 2000);
      }
      if (err.response.status === 401) {
        logout();
        navigate("/login");
      }
    }
  };

  const handleKeyDown = () => {
    if (!availableDomain) return;
    setAvailableDomain(null);
    setDomainMessage(null);
    setValue("duration", 1);
    setValue("whois", false);
  };

  const getDomains = () => {
    const data = {
      ...(!search_query ? {} : { domain_name: search_query }),
      customer_id: parseInt(id),
      pagination: { page: list_page, page_size: 30 },
    };
    update({ list_loading: true });
    Axios()
      .post(env.API_URL + "/v1/enterprise/domains", data, { headers: headers })
      .then((res) => {
        update({ list: res.data });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            logout();
            navigate("/login");
          }
        }
      })
      .finally(() => update({ list_loading: false }));
  };

  const checkAvailability = () => {
    if (value.name.match(/^\s+$|^$/gi)) {
      setDomainMessage({ type: "err", content: "please input domain name." });
      setTimeout(() => setDomainMessage(null), 2000);
      return;
    }
    const name = clipQuery(value.name);
    setSearching(true);
    Axios()
      .post(`${env.API_URL}/v1/rest/domain_availability`, {
        domain_name: name.split(".")[0],
        tld: name.split(".")[1],
      })
      .then((res) => {
        const { naira_price } = res.data;
        setDomainData(res.data);
        if (res.data.domain_name_available && res.data.tld_available) {
          setAvailableDomain(
            `${t("customer_profile.domains.modal.available")}${naira_price}/yr`
          );
        } else {
          setDomainMessage({
            type: "err",
            content: "customer_profile.domains.modal.errors.unavailable",
          });
          setTimeout(() => setDomainMessage(null), 2000);
          setAvailableDomain(null);
          setSearching(false);
        }
        setSearching(false);
      })
      .catch((err) => catch_all(err));
  };

  const handleCancel = () => {
    reset({ name: "" });
    setShowModal(false);
    setAvailableDomain(false);
    setDomainMessage(null);
    $("#exampleModal").hide();
    $(".modal-backdrop").remove();
  };

  const onSubmit = (values) => {
    update({ loading: true });
    const name = clipQuery(values.name)
    const data = {
      domain_name: name.split(".")[0],
      tld: name.split(".")[1],
      customer_id: parseInt(id),
      duration: parseInt(values.duration),
      domain_privacy: values.whois,
    };
    Axios()
      .post(`${env.API_URL}/v1/enterprise/domain/order`, data, {
        headers: headers,
      })
      .then((res) => {
        const { status, message } = res.data[0];
        if (status === "success") {
          update({ success_message: "customer_profile.domains.order_success" });
          setTimeout(() => update({ success_message: null }), 2000);

          handleCancel();
          getDomains();
        } else {
          update({ error: message });
          setTimeout(() => update({ error: null }), 2000);
        }
      })
      .catch((err) => catch_all(err))
      .finally(() => update({ loading: false }));
  };

  const form = useForm({});

  const { handleSubmit, watch, setValue, reset } = form;
  const value = watch();

  useEffect(() => {
    if (!user.data) return;
    getDomains();
    return () => dispatch(clear_page_data());
  }, [user]);

  useEffect(() => {
    if (!user.data) return;
    if (list_page !== list?.meta_data?.page) getDomains();
  }, [list_page]);

  useEffect(() => {
    !value.name && setValue("name", "");
  }, []);

  return (
    <Fragment>
      <div className="tab-body">
        <div className="row">
          <div className="col-sm-12">
            <SearchBar
              query={search_query}
              change={(e) => update({ search_query: e.target.value })}
              click={() => getDomains()}
              loading={loading}
              classname="col-sm-9 fl-left"
            />
            <button
              className="fl-right btn btn-primary"
              data-toggle="modal"
              onClick={() => setShowModal(true)}
              data-target="#exampleModal"
              id="myBtn"
            >
              {t("customer_profile.add_button")}
            </button>
          </div>
        </div>
        {success_message && <SuccessMessage />}
        {list_loading && <ListLoadingIcon />}
        {list && !list_loading && <DomainList />}
        {list && list?.meta_data?.total_pages > 1 && <PageControl />}
      </div>
      <DomainModal
        show={showModal}
        close={handleCancel}
        availableDomain={availableDomain}
        checkAvailability={checkAvailability}
        message={domainMessage}
        form={form}
        searching={searching}
        keydown={handleKeyDown}
        loading={loading}
        submit={handleSubmit((values) => onSubmit(values))}
        data={domainData}
        values={watch()}
      />
    </Fragment>
  );
};

export default CustomerDomains;
