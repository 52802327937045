import { Fragment } from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <AdminHeader />
      <div className="admin-body">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="ctn-404">
                <img
                  src="../img/404.png"
                  srcset="../img/404@2x.png 2x"
                  alt=""
                />
                <h3>Something's gone wrong.</h3>
                <p>
                  Sorry the page you are looking for doesn't exist, or might be
                  broken or might have been renamed. Check your URL to ensure
                  you have the right link or return to the{" "}
                  <a href="#" onClick={() => navigate("/enterprise")}>
                    homepage
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
