import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Axios from "../../axios";
import { logout, getCookie, clipQuery } from "../../utils/utils";

const HostingOrderModal = (props) => {
  const { t } = useTranslation();

  const [availability, setAvailability] = useState(false);
  const env = useSelector((state) => state.env);
  const headers = { "access-token": getCookie("access-token") };
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [select_duration, set_select_duration] = useState("1 YEARLY");

  const { close, successMessage } = props;
  const params = useParams();

  const catchAll = (err) => {
    if (err?.response?.status === 401) {
      logout();
      navigate("/login");
      setLoading(false);
      return;
    }
    setError(err?.response?.data?.message || t("signup.errors.error_network"));
    setTimeout(() => setError(null), 2000);
    setLoading(false);
  };

  const handleDurationChange = (n) => {
    setValue("duration", parseInt(n.split(" ")[0]));
    setValue("span", n.split(" ")[1]);
    set_select_duration(n);
  };

  const checkAvailability = () => {
    Axios()
      .post(
        `${env.API_URL}/v1/enterprise/hosting_availability`,
        { domain_name: clipQuery(watch("domain_name")) },
        { headers: headers }
      )
      .then((res) => {
        getPlans();
        setAvailability(true);
      })
      .catch((err) => {
        catchAll(err);
      });
  };

  const orderHosting = () => {
    values.duration = parseInt(values.duration);
    values.plan_id = parseInt(values.plan_id);
    values.customer_id = parseInt(params.id);
    values.domain_name = clipQuery(values.domain_name);
    setLoading(true);
    Axios()
      .post(`${env.API_URL}/v1/enterprise/hosting/order`, values, {
        headers: headers,
      })
      .then((res) => {
        if (res.data[0].status === "error") {
          setError(res.data[0].message);
          setTimeout(() => setError(null), 2000);
          setLoading(false);
          return;
        }
        reset({ domain_name: "", duration: 1, span: "YEARLY" });
        successMessage();
        setLoading(false);
      })
      .catch((err) => {
        catchAll(err);
      });
  };

  const { register, watch, setValue, reset } = useForm({
    customer_id: parseInt(),
  });

  const values = watch();

  const getPlans = () => {
    Axios()
      .get(`${env.API_URL}/v1/rest/plans`)
      .then((res) => {
        setPlans(res.data);
        setValue("plan_id", "1");
      });
  };

  useEffect(() => {
    handleDurationChange("1 YEARLY");
    setSelectedPlan(plans[watch("plan_id") - 1]);
    if (selectedPlan) {
      if (
        select_duration.split(" ")[1] === "MONTHLY" &&
        plans[values.plan_id - 1]?.description === "Personal_Plan"
      ) {
        handleDurationChange("1 YEARLY");
        set_select_duration("1 YEARLY");
      }
    }
  }, [values.plan_id]);

  return (
    <div
      className="modal"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="renewHostingModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("hosting_page.modal.header")}
            </h5>
            <button
              onClick={close}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="input-ctn">
                  <label>{t("hosting_page.modal.form.name")}</label>
                  <input
                    name="domain_name"
                    {...register("domain_name")}
                    type="text"
                    onKeyUp={() => setAvailability(false)}
                  />
                  <span className="badge badge-primary w-100 mt-2">
                    {error && (
                      <small id="sh-text7" className="form-text mt-0 text-left">
                        {error}
                      </small>
                    )}
                  </span>
                </div>

                {availability && (
                  <Fragment>
                    <div className="input-ctn">
                      <label>{t("hosting_page.modal.form.plan")}</label>
                      <select {...register("plan_id")} className="form-control">
                        {plans?.map((d, index) => (
                          <option
                            key={d.id}
                            value={d.id}
                          >{`${d.description}`}</option>
                        ))}
                      </select>
                    </div>

                    <div className="input-ctn">
                      <label>{t("hosting_page.modal.form.duration")}</label>
                      <select
                        value={select_duration}
                        className="form-control"
                        onChange={(e) => handleDurationChange(e.target.value)}
                      >
                        {selectedPlan?.available_monthly &&
                          [1, 3, 6].map((count) => (
                            <option value={count + " MONTHLY"}>
                              {t("hosting_page.modal.form.month", { count })}
                            </option>
                          ))}
                        {[1, 2, 3].map((count) => (
                          <option value={count + " YEARLY"}>
                            {t("hosting_page.modal.form.year", { count })}
                          </option>
                        ))}
                      </select>
                    </div>
                    {values.span === "MONTHLY"
                      ? values.plan_id && (
                          <p>
                            {t("hosting_page.modal.total")}:{" "}
                            <span>
                              <b>
                                {t("currency.NGN") +
                                  (
                                    selectedPlan?.naira_monthly *
                                    values.duration
                                  ).toLocaleString("EN-US")}
                              </b>
                            </span>
                          </p>
                        )
                      : values.plan_id && (
                          <p>
                            {t("hosting_page.modal.total")}:{" "}
                            <span>
                              <b>
                                {t("currency.NGN") +
                                  (
                                    selectedPlan?.naira_yearly * values.duration
                                  ).toLocaleString("EN-US")}
                              </b>
                            </span>
                          </p>
                        )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {availability ? (
              <button
                onClick={() => orderHosting()}
                className="btn btn-success"
              >
                {loading ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  t("hosting_page.modal.form.buy")
                )}
              </button>
            ) : (
              <button
                onClick={() => checkAvailability()}
                className="btn btn-success"
              >
                {loading ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  t("hosting_page.modal.form.search")
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingOrderModal;
