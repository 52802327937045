import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  data : null
}



export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers : {
    login : (state , action) => {
      // state.data = action.payload;
      return {
        ...state,
        data : action.payload,
        req_type : action?.payload?.role === 'CUSTOMER' ? 'rest': 'enterprise'
      }
    },
    logOut : (state) =>  {
      state.data = null
      state.req_type = null
    }
  }
})

export const { login , logOut} = userSlice.actions

export default userSlice.reducer