import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DomainRenewal from "../../../components/DomainRenewal/DomainRenewal";
import { update_page_data } from "../../../Redux/reducers/pageSlice";
import DomainItem from "../DomainItem/DomainItem";
import NoDomains from "../NoDomains";

const DomainList = (props) => {
  const { domains } = props;

  const { t } = useTranslation();
  const {
    user,
    page: { list, selected_item },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const update = (data) => dispatch(update_page_data(data));

  const [selectedItem, setSelectedItem] = useState(null);

  return list?.meta_data?.total_count > 0 ? (
    <div className="ls-table mt-2">
      {selected_item && <DomainRenewal domainData={selected_item} />}
      <div className="table-responsive">
        <table className="table" data-testid="domain-table">
          <thead>
            <tr>
              <th>{t("domains_page.table.headers.name")}</th>
              {user?.data?.role == "SUPER-ADMIN" && (
                <th>{t("domains_page.super_admin_table.headers.customer")}</th>
              )}
              <th>{t("domains_page.table.headers.created")}</th>
              <th>{t("domains_page.table.headers.expires")}</th>
              <th>{t("domains_page.super_admin_table.headers.whois")}</th>
              <th>{t("domains_page.table.headers.status")}</th>
              <th className="text-center">
                {t("domains_page.table.headers.action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {list.data.map((item, index) => (
              <DomainItem
                key={index}
                onClick={() => update({ selected_item: item })}
                domain={item}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <NoDomains />
  );
};

export default DomainList;
