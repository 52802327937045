import OnboardingSideContent from '../../components/OnboardingSideContent/OnboardingSideContent'
import MainContent from './MainContent/MainContent'

const Signup = () => {

    return (
        <div className="admin-login">
            <OnboardingSideContent/>
            <MainContent/>
        </div>

    )
}

export default Signup
