import { useEffect, useState } from "react";
import CountryInput from "../../../components/NewCustomerForm/CountryInput/CountryInput";
import TextInput from "../../../components/TextInput/TextInput";
import countries from "../../../utils/countries";
import cities from "../../../utils/cities";
import { useTranslation } from "react-i18next";
import SuccessMessage from "../../../components/SuccessMessage/SuccessMessage";
import OnboardinError from "../../../components/OnboardingError/OnboardingError";
import { useForm } from "react-hook-form";
import Axios from "../../../axios";
import { logout, getCookie } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

const CustomerEdit = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [availableStates, setAvailableStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: null, content: null });
    const [ready, setReady] = useState(false);
    const id = params?.id;
    const headers = { "access-token": getCookie("access-token") };
    const env = useSelector((state) => state.env);

    const handleCountry = (e) => {
        setValue("contact.country", e.target.value);
        setValue("contact.city", "");
        const n = e.target.options[e.target.selectedIndex].textContent;
        setAvailableStates(cities[n] ? cities[n].states : []);
    };

    const getUserData = () => {
        Axios()
            .get(`${env.API_URL}/v1/enterprise/customer/${id}`, { headers: headers })
            .then((res) => {
                delete res.data.id;
                reset(res.data);
                setTimeout(() => setValue("contact.city", res?.data?.contact?.city), 0);
                for (let i = 0; i < countries.length; i++) {
                    if (countries[i].isoCode === res.data.contact.country) {
                        setAvailableStates(cities[countries[i].name].states);
                        break;
                    }
                }
                setReady(true);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    logout();
                    navigate("/login");
                } else if (err?.response?.status === 404) navigate("/404");
            });
    };

    const onSubmit = (values) => {
        setLoading(true);
        Axios()
            .patch(`${env.API_URL}/v1/enterprise/customer/${id}`, values, {
                headers: headers,
            })
            .then((res) => {
                getUserData();
                setLoading(false);
                setMessage({
                    type: "success",
                    content: "customer profile updated successfully",
                });
                setTimeout(() => setMessage({ type: null }), 2000);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    logout();
                    navigate("/login");
                }
                if (err?.response?.data?.message) {
                    setMessage({ type: "error", content: err.response.data.message });
                    setTimeout(() => setMessage({ type: null }), 2000);
                }
                setLoading(false);
            });
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
    } = useForm({});

    useEffect(() => {
        getUserData();
    }, [env]);

    return (
        <div className="tab-body">
            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="mb-40">
                        {t("customer_profile.edit.header")}
                        <span>{t("customer_profile.edit.sub_head")}</span>
                    </p>
                </div>

                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="row">
                        {message.type === "success" && <SuccessMessage message={message.content} />}
                        {message.type === "error" && <OnboardinError error={message.content} />}
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput
                                label="add_customer.form.last_name"
                                name="customer.first_name"
                                register={register}
                                type="text"
                                error={errors?.customer && errors.customer.first_name}
                            />
                        </div>

                        <div className="col-sm-6">
                            <TextInput
                                label="add_customer.form.last_name"
                                name="customer.last_name"
                                register={register}
                                type="text"
                                error={errors?.customer && errors.customer.last_name}
                            />
                        </div>
                    </div>
                    <TextInput
                        label="add_customer.form.email"
                        name="customer.email"
                        register={register}
                        type="text"
                        error={errors?.customer && errors.customer.email}
                    />
                    <TextInput
                        label="add_customer.form.phone"
                        name="customer.phone"
                        register={register}
                        type="text"
                        error={errors?.customer && errors.customer.phone}
                    />
                    <TextInput
                        label="add_customer.form.address"
                        name="contact.address"
                        register={register}
                        type="text"
                        error={errors.contact && errors.contact.address}
                    />
                    <CountryInput changeCountry={(e) => handleCountry(e)} register={register} errors={errors} states={availableStates} />
                    <button type="submit" onClick={handleSubmit((values) => onSubmit(values))} className="green-btn block-btn mt-10">
                        {loading ? <i className="fas fa-circle-notch fa-spin"></i> : t("add_customer.form.save")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomerEdit;
