import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import ButtonWithSpinner from '../ButtonWithSpinner/ButtonWithSpinner';
import { useSelector } from 'react-redux';
import OnboardinError from '../../components/OnboardingError/OnboardingError'

const DomainModal = (props) => {
  
	const {t} = useTranslation()
  const {data, close , message , submit , form , availableDomain , checkAvailability , searching , loading , keydown , values} = props

  const {page : {error}} = useSelector(state => state);

  const {register} = form
  
  return (
  <div className='modal'
	id="exampleModal"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
  >	
		<div className='modal-dialog' role='document'>

			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">
						{t('customer_profile.domains.modal.header')}
					</h5>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}
					>
						<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x">
							<line x1="18" y1="6" x2="6" y2="18"></line>
							<line x1="6" y1="6" x2="18" y2="18"></line>
						</svg>
					</button>
				</div>
				<div className='modal-body'>
					<div className="row">
						<div className="col-sm-12">
							<span className="badge badge-primary w-100 mt-2">
								{error && <small id="sh-text7" className="form-text mt-0 text-left">{error}</small>}
							</span>
							<div className="input-ctn">
								<label>{t('customer_profile.domains.modal.input_name')}</label>
								<input name="text" type="text" {...register('name')} onKeyDown={keydown} />
								{
									availableDomain && 
										<span className={`badge badge-success w-100 mt-2`}>
											<small id="sh-text7" className="form-text mt-0 text-left">
												{availableDomain}
											</small>
										</span>
								}
								{message &&
									<span className={`badge badge-${message.type === "err" ? 'warning' : 'success'} w-100 mt-2`}>
										<small id="sh-text7" className="form-text mt-0 text-left">
											{t(message.content)}
										</small>
									</span>
								}
								{!availableDomain &&
									<div className='modal-footer'>
										<ButtonWithSpinner classname = "btn btn-success" onclick={checkAvailability} loading = {searching} text = 'customer_profile.domains.modal.buy_button'/>
									</div>
								}
							</div>
							{availableDomain &&
								<Fragment>
									<div className="input-ctn">
										<label>{t('customer_profile.domains.modal.duration')}</label>
										<select className="form-control" {...register('duration')} >
											<option value={1} >{t('customer_profile.domains.modal.one')}</option>
											<option value={2} >{t('customer_profile.domains.modal.two')}</option>
											<option value={3} >{t('customer_profile.domains.modal.three')}</option>
										</select>
									</div>
									<div className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" {...register('whois')} id="customCheck1" />
										<label className="custom-control-label" for="customCheck1">{t('customer_profile.domains.modal.whois')}</label>
									</div>
								</Fragment>
							}

							{
								(data?.naira_price && availableDomain) && 
									<p className="p-footer">Total:
										{
											values.whois ? 
											<span>{t(`currency.NGN`)}{((parseInt(data.naira_price) + 1400)  * values.duration).toLocaleString("EN-US")}</span> :
											<span>{t(`currency.NGN`)}{(parseInt(data.naira_price) * values.duration).toLocaleString("EN-US")}</span>
										} 
									</p>
							}
						</div>
					</div>
				</div>
				{availableDomain &&
				<div className="modal-footer" >
					<ButtonWithSpinner classname = "btn btn-success" onclick={submit} loading = {loading} text = 'customer_profile.domains.modal.buy_button'/>
				</div>
				}
			</div>
		</div>
</div>
  )
};

export default DomainModal;
