import { useTranslation } from "react-i18next";

const TextInput = (props) => {
    const { t } = useTranslation();

    const { register, name, type, disabled } = props;

    return (
        <div className="input-ctn">
            <label>{t(props.label)}</label>
            <input data-testid={props.name} {...register(name)} type={type} disabled={disabled} onKeyUp={props.keyup} />
            {props.error ? <span className="invalid-feedback">{t(props.error.message)}</span> : null}
        </div>
    );
};

export default TextInput;
