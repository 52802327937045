/* eslint-disable no-unreachable */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Axios from "../../axios";
import SiteLoadingComponent from "../../components/SiteLoadingComponent/SiteLoadingComponent";
import { update_payment_status } from "../../Redux/reducers/paymentSlice";
import { getCookie, logout } from "../../utils/utils";
import { processOrder, verifyPayment } from "../Dashboard/dashboard_actions";

const OrderProcessing = () => {
  const navigate = useNavigate();
  const getData = (name) => new URLSearchParams(search).get(name);
  const search = useLocation().search;
  const { env } = useSelector((e) => e);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const transaction_data = JSON.parse(getData("transaction-data"));

    if (transaction_data?.order_id) {
      const { order_id, cart_id } = transaction_data;
      Axios()
        .post(
          `${env.API_URL}/v1/rest/${order_id}/${cart_id}/process`,
          transaction_data,
          {
            headers: { "access-token": getCookie("access-token") },
          }
        )
        .then((res) => {
          const error = res?.data.find((e) => e.status !== "successful");
          if (error) {
            dispatch(update_payment_status({ error: error.message }));
            navigate("/enterprise");
            return;
          }
          localStorage.removeItem("cart_id");
          window.history.replaceState({}, document.title);
          dispatch(
            update_payment_status({ message: "Order completed successfully" })
          );
          setTimeout(() => navigate("/enterprise"), 0);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            logout();
            navigate("/login");
          } else {
            dispatch(
              update_payment_status({
                error: err?.response?.data?.message || t("try_again"),
              })
            );
          }
        })
        .finally(() => {
          window.history.replaceState({}, document.title);
        });
    } else {
      const { status, transaction_id, transaction_ref, cart_id } =
        transaction_data;

      if (status === "completed") {
        const data = {
          transaction_id: transaction_id,
          transaction_ref: transaction_ref,
        };
        Axios()
          .post(`${env.API_URL}/v1/payment/verify`, data, {
            headers: { "access-token": getCookie("access-token") },
          })
          .then(() => {
            navigate("/enterprise/dashboard");
          })
          .catch((err) => {
            if (err?.response?.status === 401) {
              logout();
              navigate("/login");
            } else {
              dispatch(
                update_payment_status({
                  error: err?.response?.data?.message || t("try_again"),
                })
              );
              navigate("/enterprise/dashboard");
            }
          })
          .finally(() => window.history.replaceState({}, document.title));
      }
    }
  }, []);

  return <SiteLoadingComponent message="Processing Payment" />;
};

export default OrderProcessing;
