import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { update_page_data } from '../../Redux/reducers/pageSlice';

const PageControl = (props) => {


    const dispatch = useDispatch();
    const update = (data) => dispatch(update_page_data(data));

    const to = (destination) => {
        if (destination > total_pages) return;
        update({list_page : destination})
    }

    const {page : {list : {meta_data : {total_pages}}, list_page}} = useSelector(state => state);

    return (
        <div className = "container">
            <div className='row justify-content-center'>
                <button onClick={() =>to(list_page-1)} className='btn btn-secondary' style={{outline : "none"}} disabled={list_page === 1}>{"<"}</button>
                
                <div className='btn' disabled>page {list_page} of {total_pages}</div>
                <button onClick={() => to(list_page+1)} className='btn btn-secondary' style={{outline : "none"}} disabled ={list_page === total_pages}>{">"}</button>
            </div>
        </div>
    )
}

export default PageControl