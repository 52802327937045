import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../axios";
import { update_page_data } from "../../Redux/reducers/pageSlice";
import {
  add_to_cart,
  get_cart_id,
  logout,
  redirect,
  req_type,
  update_cart,
} from "../../utils/utils";
import * as $ from "jquery";
import { useNavigate } from "react-router";
import ButtonWithSpinner from "../ButtonWithSpinner/ButtonWithSpinner";

const DomainRenewal = (props) => {
  const { domainData } = props;
  const navigate = useNavigate();

  const { meta } = domainData;
  const dispatch = useDispatch();
  const update = (d) => dispatch(update_page_data(d));
  const {
    user,
    page: { list, modal_error },
  } = useSelector((state) => state);

  const [planData, setPlanData] = useState(null);
  const [duration, setDuration] = useState(1);
  const [loading, setLoading] = useState(false);

  const env = useSelector((state) => state.env);
  const [privacy, setPrivacy] = useState(false);

  useEffect(() => {
    const url = `${env.API_URL}/v1/rest/domain_availability`;
    const d = domainData?.domain_name.split(".");
    const data = {
      domain_name: d[0],
      tld: d[1],
    };
    axios.post(url, data).then((res) => {
      setPlanData(res.data);
    });
  }, [domainData]);

  const handle_renewal = () => {
    setLoading(true);
    if (user?.data?.is_admin) {
      const data = {
        domain_id: domainData.id,
        domain_privacy: privacy,
        duration: parseInt(duration),
      };

      Axios()
        .post(`/v1/${req_type()}/domain/renew`, data)
        .then((res) => {
          const s = res.data[0];
          if (s.status !== "success") {
            update({ modal_error: s.message });
            setTimeout(() => update({ modal_error: null }), 3500);
            update({ loading: false });
            return;
          }
          $("#renewHostingModal").hide();
          $(".modal-backdrop").remove();
          update({ success_message: "Subscription renewed successfully" });
          setTimeout(() => update({ success_message: null }), 3000);
          props.getHostingList();
          update({ loading: false });
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            logout();
            navigate("/login");
            update({ loading: false });
            return;
          } else {
            update({
              modal_error:
                err?.response?.data?.message ||
                t("signup.errors.error_network"),
            });
            setTimeout(
              () => setTimeout(() => update({ modal_error: null }), 3500),
              2000
            );
            setLoading(false);
            update({ loading: false });
          }
        });
      return;
    }

    const meta_2 = {
      ...meta,
      domain_name: domainData?.domain_name,
      order_type: "DOMAIN_RENEWAL",
      domain_privacy: privacy,
    };
    const data = {
      ...domainData,
      meta: meta_2,
      cart_id: get_cart_id(),
      span: "YEARLY",
    };
    data.duration = duration;
    delete data.privacy_info;
    add_to_cart(data).then((res) => {
      redirect(`${env.CUSTOMER_URL}/cart`);
    });
  };

  const { t } = useTranslation();
  return (
    <div
      class="modal"
      id="renewHostingModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="renewHostingModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="renewHostingModalLabel">
              {t("domain_renewal_modal.header")}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <p>
                  {t("domain_renewal_modal.for")} {domainData?.domain_name}
                </p>
                <div class="input-ctn">
                  <label>{t("domain_renewal_modal.duration")}</label>
                  <select
                    class="form-control"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    {[1, 2, 3, 4, 5].map((count) => (
                      <option value={count}>
                        {t("hosting_page.modal.form.year", { count })}
                      </option>
                    ))}
                  </select>
                </div>

                <input
                  type="checkbox"
                  checked={privacy}
                  value={privacy}
                  onChange={(e) => setPrivacy(!privacy)}
                />
                <label style={{ marginLeft: "10px" }}>
                  {t("domain_renewal_modal.whois")}
                </label>

                <p>
                  Total:{" "}
                  <span>
                    <b>
                      N
                      {(parseInt(planData?.naira_price) +
                        (privacy ? 1400 : 0)) *
                        duration}
                    </b>
                  </span>
                </p>
                <span className="badge badge-primary w-100 mt-2">
                  {modal_error && (
                    <small id="sh-text7" className="form-text mt-0 text-left">
                      {modal_error}
                    </small>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <ButtonWithSpinner
              loading={loading}
              classname="btn btn-success"
              type="button"
              onclick={() => handle_renewal()}
              text="domain_renewal_modal.button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainRenewal;
