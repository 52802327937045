import { useState } from "react";
import { useTranslation } from "react-i18next";
import OnboardingSideContent from "../../components/OnboardingSideContent/OnboardingSideContent";
import TextInput from "../../components/TextInput/TextInput";
import * as Yup from "yup";
import OnboardinError from "../../components/OnboardingError/OnboardingError";
import { ref } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get_all_errors } from "../../utils/utils";
import Axios from "../../axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const NewPasswordPage = (props) => {
  const params = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { env } = useSelector((state) => state);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("password.required")
      .min(8, "password.short")
      .matches(/[A-Z]/, "password.no_uppercase")
      .matches(/[a-z]/, "password.no_lowercase")
      .matches(/[0-9]/, "password.no_integer")
      .matches(/[^a-zA-Z\d\s:]/, "password.no_symbol"),

    confirm_password: Yup.string()
      .required("cpassword.required")
      .oneOf([Yup.ref("password"), null], "cpassword.no_match"),
  });
  const onSubmit = (values) => {
    setLoading(true);
    const data = {
      token: params.id,
      password: values.password,
    };

    Axios()
      .post(`${env.API_URL}/v1/password_reset/complete`, data)
      .then((res) => {
        setLoading(false);
        navigate("/login", { state: { reset: true } });
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  return (
    <div className="admin-login">
      <OnboardingSideContent />
      <div className="main-content">
        <div class="login-page-content">
          <form className="admin-form">
            <div className="logo-small-show">
              <a href="#">
                <img
                  src="https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/logo.svg"
                  alt=""
                />
              </a>
            </div>

            <h3 className="mb-15">{t("new_password.header")}</h3>
            <p classname="pwinstruction">{t("new_password.sub_header")}</p>

            {error && <OnboardinError error={error} />}
            {get_all_errors(errors).length > 0 && (
              <OnboardinError error={`${get_all_errors(errors).pop()}`} />
            )}

            <TextInput
              label="new_password.password"
              type="password"
              name="password"
              register={register}
            />

            <TextInput
              label="new_password.confirm_password"
              type="password"
              name="confirm_password"
              register={register}
            />

            <button
              type="submit"
              className={`green-btn block-btn mt-10`}
              onClick={handleSubmit((values) => onSubmit(values))}
            >
              {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                t("new_password.button")
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordPage;
