import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../axios";
import {
  add_to_cart,
  getCookie,
  get_cart_id,
  logout,
  redirect,
  req_type,
} from "../../utils/utils";
import * as $ from "jquery";
import { update_page_data } from "../../Redux/reducers/pageSlice";
import ButtonWithSpinner from "../ButtonWithSpinner/ButtonWithSpinner";
import { useNavigate } from "react-router";

const HostingUpgradeModal = (props) => {
  const {
    env,
    user,
    plans,
    page: { loading, selected_subscription },
  } = useSelector((state) => state);
  const { data } = props;
  const [select_duration, set_select_duration] = useState("1 YEARLY");
  const [chosenPlan, setChosenPlan] = useState(
    plans.find((e) => e.id == data.plan_id)
  );
  const [quote, setQuote] = useState(null);
  const [requestError, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const update = (data) => dispatch(update_page_data(data));

  const [rank, setRank] = useState(1);

  useEffect(() => update({ loading: true }), []);

  useEffect(() => {
    setRank(plans.find((e) => e.id == data.plan_id)?.rank);
  }, [selected_subscription]);

  useEffect(() => {
    setValue("select_duration", "1_YEARLY", { shouldDirty: false });
  }, [data.plan_id]);

  const form = useForm({});
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    reset,
    formState: { isDirty, dirtyFields },
  } = form;

  const values = watch();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isReady) return;
    setIsReady(dirtyFields.plan_id ? true : false);
  }, [dirtyFields.plan_id, dirtyFields.select_duration]);

  useEffect(() => {
    (dirtyFields.plan_id || isReady) && getQuote();
  }, [chosenPlan, getValues("select_duration")]);

  useEffect(() => {
    const current = plans.find((e) => e.id == data.plan_id);
    setChosenPlan(current);
    reset(
      { select_duration: "1 YEARLY", plan_id: t("select_new_plan") },
      { keepDirty: false, keepErrors: false, keepTouched: false }
    );
    setIsReady(false);
  }, [data?.plan_id]);

  useEffect(() => {
    setChosenPlan(plans.find((e) => e.id == getValues("plan_id")));
  }, [getValues("plan_id")]);

  useEffect(() => {
    if (!chosenPlan?.available_monthly && values.span === "MONTHLY") {
      set_select_duration("1 YEARLY");
    }
  }, [chosenPlan]);

  const getQuote = () => {
    update({ loading: true });
    setQuote(null);
    const request_data = {
      upgrade_plan_key: chosenPlan.key.toString(),
      span: getValues().select_duration.split(" ")[1],
      duration: parseInt(getValues().select_duration.split(" ")[0]),
    };
    axios
      .post(
        `${env.API_URL}/v1/${req_type()}${
          req_type() === "rest" ? "/cart" : ""
        }/${data.id}/upgrade_quote`,
        request_data,
        {
          withCredentials: true,
          headers: { "access-token": getCookie("access-token") },
        }
      )
      .then((res) => {
        setQuote(res.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
          navigate("/login");
        } else {
          setError(err?.response?.data?.message || t("try_again"));
          setTimeout(() => setError(null), 2000);
        }
      })
      .finally(() => update({ loading: false }));
  };

  const handleUpgrade = (values) => {
    update({ loading: true });
    if (!quote) return;
    if (user?.data?.is_admin) {
      const d = {
        subscription_id: data.id,
        plan_id: parseInt(getValues("plan_id")),
        span: values.select_duration.split(" ")[1],
        duration: parseInt(values.select_duration.split(" ")[0]),
      };
      Axios()
        .post(`${env?.API_URL}/v1/${req_type()}/hosting/upgrade`, d)
        .then((res) => {
          const s = res.data[0];
          if (s.status !== "success") {
            setError(s?.message);
            setTimeout(() => setError(null), 3500);
            return;
          }
          $("#upgradeModal").hide();
          $(".modal-backdrop").remove();
          update({ success_message: "Subscription renewed successfully" });
          setTimeout(() => update({ success_message: null }), 3000);
          props.getHostingList();
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            logout();
            navigate("/login");
            return;
          } else {
            setError(
              err?.response?.data?.message || t("signup.errors.error_network")
            );
            setTimeout(() => setError(null), 2000);
          }
        })
        .finally(() => update({ loading: false }));

      return;
    }

    const upgrade_data = {
      cart_id: get_cart_id(),
      span: values.select_duration.split(" ")[1],
      duration: parseInt(values.select_duration.split(" ")[0]),
      meta: {
        domain_name: data.domain_name,
        order_type: "HOSTING_UPGRADE",
        plan_key: chosenPlan.key.toString(),
      },
    };
    add_to_cart(upgrade_data).then(redirect(`${env.CUSTOMER_URL}/cart`));
  };

  const { t } = useTranslation();

  return (
    <div
      className="modal"
      id="upgradeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="upgradeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="upgradeModalLabel">
              Upgrade Hosting Plan
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <p>Subscription upgrade for {data.domain_name}</p>
                <br />
                <div className="input-ctn">
                  <label>New Plan</label>
                  <select {...register("plan_id")} className="form-control">
                    {!dirtyFields.plan_id && (
                      <option>{t("select_new_plan")}</option>
                    )}
                    {plans &&
                      plans?.map(
                        (d, index) =>
                          d.rank > rank && (
                            <option
                              key={d.id}
                              value={d.id}
                            >{`${d.description}`}</option>
                          )
                      )}
                  </select>
                </div>

                <div className="input-ctn">
                  <label>Duration</label>
                  <select
                    {...register("select_duration")}
                    className="form-control"
                  >
                    <option>{t("select_new_plan")}</option>
                    {chosenPlan?.available_monthly &&
                      [1, 3, 6].map((count) => (
                        <option value={count + " MONTHLY"}>
                          {t("hosting_page.modal.form.month", { count })}
                        </option>
                      ))}
                    {[1, 2, 3].map((count) => (
                      <option value={count + " YEARLY"}>
                        {t("hosting_page.modal.form.year", { count })}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="badge badge-primary w-100 mt-2">
                  {requestError && (
                    <small id="sh-text7" className="form-text mt-0 text-left">
                      {requestError}
                    </small>
                  )}
                </span>
                {isDirty && quote && !loading && (
                  <Fragment>
                    <p>
                      New Plan{" "}
                      <span>
                        <b>N{quote.new_plan_cost.toLocaleString()}</b>
                      </span>
                    </p>

                    <p>
                      Previous Balance:{" "}
                      <span>
                        <b>N{quote.previous_plan_balance.toLocaleString()}</b>
                      </span>
                    </p>

                    <p>
                      Amount Due:{" "}
                      <span>
                        <b>N{quote.new_cost.toLocaleString()} </b>
                      </span>
                    </p>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isDirty && quote && (
              <ButtonWithSpinner
                onclick={handleSubmit((values) => handleUpgrade(values))}
                classname="btn btn-success"
                loading={loading}
                text="Buy Hosting"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingUpgradeModal;
