import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const OnboardingSideContent = () => {
    
    const {t} = useTranslation()
    const env = useSelector(s => s.env)

    return (
        <div className="side-content" style = {{backgroundImage : `url(https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/pattern-7.svg)`}}>
            <div className="col-sm-12">
                <a href={env.CUSTOMER_URL}>
                    <img src="https://res.cloudinary.com/dtuafcbbd/image/upload/v1638970609/leanstack/img/white-logo.svg" alt='' />
                </a>
                <div className="fix-bottom">
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <h1>{t('side_content.main_header')}</h1>
                            <p>{t('side_content.sub_header')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingSideContent
