import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import enTranslation from './en/translation.json'


i18n
.use(detector)
.use(backend)
.use(initReactI18next)
  .init({
    resources:{
      en: {translation : enTranslation}
    },
    lng : "en",
    fallbackLng: "en",
    interpolation: {escapeValue : false}
  })


export default i18n;