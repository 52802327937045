import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SuccessMessage = (props) => {
    
    const {t} = useTranslation()
    const {success_message} = useSelector(state => state.page)

    return (
        <div className="row">
            <div className="col-sm-12  mb-2">
                <div data-testid = "success-message" className="alert alert-success" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
                    {t(props.message || success_message)}
                </div> 
            </div>
        </div>
    )
}

export default SuccessMessage
