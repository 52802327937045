import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import OnboardinError from "../../components/OnboardingError/OnboardingError";
import OnboardingSideContent from "../../components/OnboardingSideContent/OnboardingSideContent";
import TextInput from "../../components/TextInput/TextInput";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import {
  redirect,
  req_type,
  update_userdata,
  getCookie,
  setCookie,
} from "../../utils/utils";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import Axios from "../../axios";
import { useSelector } from "react-redux";

const Login = (props) => {
  const headers = { "access-token": getCookie("access-token") };
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const env = useSelector((state) => state.env);
  const location = useLocation();
  const { user } = useSelector((state) => state);

  useEffect(() => {
    if (location.state) {
      location.state?.reset && setSuccess("login.reset_success");
      setTimeout(() => {
        setSuccess(null);
        window.history.replaceState("", null);
      }, 3000);
    }
  }, []);

  useEffect(() => {}, []);

  const search = useLocation().search;
  const getData = (name) => new URLSearchParams(search).get(name);
  const onSubmit = (values) => {
    setLoading(true);
    Axios()
      .post(
        process.env.REACT_APP_API_URL + "/v1/temporary_access_token",
        values,
        { headers: { "Access-Control-Allow-Origin": "http://localhost" } }
      )
      .then((response) => {
        setCookie("access-token", response.data["access-token"], 1);
        setCookie("auth-id", response.data["auth-id"], 1);

        localStorage.setItem("auth-id", response.data["auth-id"]);
        setLoading(false);
        setError(null);
        update_userdata(navigate);
        setTimeout(async () => {
          if (getCookie("redirect")) {
            const cookie = getCookie("redirect");
            setCookie("redirect", null);
            redirect(env.CUSTOMER_URL + "/" + cookie);
            return;
          } else {
            const us = await Axios().get("/v1/me");
            if (us?.data?.is_admin) {
              navigate("/enterprise/dashboard");
            } else {
              navigate("/enterprise/domains");
            }
          }
        }, 200);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setError(`login.error`);
            setTimeout(() => {
              setError(null);
            }, 2000);
          }
        } else {
          setError("login.error_network");
          setTimeout(() => setError(null), 2000);
        }

        setLoading(false);
      });
  };

  const { register, handleSubmit } = useForm({});

  return (
    <div className="admin-login">
      <OnboardingSideContent />
      <div className="main-content">
        <div className="login-page-content">
          <form className="admin-form">
            <div className="logo-small-show">
              <a href={env.CUSTOMER_URL}>
                <img src="/img/logo.svg" />
              </a>
            </div>
            <h3 className="mb-15" data-testid="header">
              {t("login.header")}
            </h3>
            <p className="pwinstruction">{t("login.sub_header")}</p>

            {error && <OnboardinError error={error} />}
            {success && <SuccessMessage message={success} />}

            <TextInput
              label="login.form.email"
              register={register}
              name="email"
              type="text"
            />

            <TextInput
              label="login.form.password"
              register={register}
              name="password"
              type="password"
            />

            <button
              disabled={loading}
              type="submit"
              data-testid="submit"
              onClick={handleSubmit((values) => onSubmit(values))}
              className={`green-btn block-btn mt-10`}
            >
              {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                t("login.form.submit")
              )}
            </button>

            <Link
              to={`/forgot-password`}
              className="margin-auto forgot-password"
            >
              {t("login.forgot_password")}
            </Link>

            <p className="text-center new-account" style={{display:'none'}} >
              {t("login.signup_text")}{" "}
              <Link to="/signup">{t("login.signup_link")}</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
