import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import userReducer from "./reducers/userSlice";
import envReducer from "./reducers/envSlice";
import plansReducer from "./reducers/plansSlice";
import cartSlice from "./reducers/cartSlice";
import supportSlice from "./reducers/supportSlice";
import metricsSlice from "./reducers/metricsSlice";
import pageSlice from "./reducers/pageSlice";
import persistStore from "redux-persist/es/persistStore";
import paymentSlice from "./reducers/paymentSlice";

const reducers = combineReducers({
  user: userReducer,
  env: envReducer,
  plans: plansReducer,
  cart: cartSlice,
  support: supportSlice,
  page: pageSlice,
  metrics: metricsSlice,
  payment: paymentSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.REACT_APP_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;

export const persistor = persistStore(store, { blacklist: ["page"] });
