import { useState } from "react";
import { Link } from "react-router-dom";
import DomainStatus from "./DomainStatus";
import * as $ from "jquery";
import ClickAwayListener from "react-click-away-listener";
import DomainRenewal from "../../../components/DomainRenewal/DomainRenewal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DomainItem = (props) => {
  const [showOptions, setShowOptions] = useState(false);

  const {
    domain_name,
    created_at,
    expiry,
    current_status,
    id,
    customer_name,
    privacy_info,
  } = props.domain;
  const openDropdown = () => {
    props.onClick();
    $(`.action-dropdown.shadow.${id}`).fadeIn(100);
    setShowOptions(true);
  };

  const closeDropdown = () => {
    if (!showOptions) return;
    $(`.action-dropdown.shadow.${id}`).fadeOut(100);
    setShowOptions(false);
  };

  const { t } = useTranslation();
  const { user } = useSelector((state) => state);

  return (
    <tr>
      <td>{domain_name}</td>
      {user?.data?.role == "SUPER-ADMIN" && <td>{customer_name}</td>}
      <td>{created_at}</td>
      <td>{expiry}</td>
      <td>{t(privacy_info ? " YES" : "NO")}</td>
      <td>
        <DomainStatus status={current_status} />
      </td>
      <td>
        <div>
          <div
            className="action-dropdown-ctn"
            onClick={() => openDropdown()}
            disabled={showOptions}
          >
            <i className="fas fa-ellipsis-h"></i>
          </div>
          <ClickAwayListener onClickAway={closeDropdown}>
            <div className={`action-dropdown shadow ${id}`}>
              <Link className="dropdown-item" to={`/enterprise/domain/${id}`}>
                Manage Domain
              </Link>
              <a
                type="button"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#renewHostingModal"
                id="myBtn"
              >
                Renew
              </a>
            </div>
          </ClickAwayListener>
        </div>
      </td>
    </tr>
  );
};

export default DomainItem;
